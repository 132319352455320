import { BigCard, FullWithSection } from "../components/big-card";
import Container from "../components/container";
import Hero from "../components/hero";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";
import SPALogo from "../images/partners/spa-logo.png";
import SPA1 from "../images/partners/spa1.jpg";
import SPA2 from "../images/partners/spa2.jpg";
import Article1Logo from "../images/partners/article1-logo.png";
import Article1Banner from "../images/partners/article1-banner.jpg";
import TheShiftProjectLogo from "../images/partners/the-shift-project-logo.png";
import TheShiftProject from "../images/partners/the-shift-project.jpg";
import { useState } from "react";

interface Partner {
  name: string;
  logo: string;
}

export default function Partners() {
  const partners: Partner[] = [
    { name: "spa", logo: SPALogo },
    { name: "article1", logo: Article1Logo },
    { name: "the-shift-project", logo: TheShiftProjectLogo },
  ];

  const [activePartnerName, setActivePartnerName] = useState("spa");

  return (
    <>
      <Hero image={HeroBlanket} overlay="dark">
        <Container>
          <span className="text-white text-4xl text-center font-extralight pt-6 pb-12">
            Les associations
            <br />
            soutenues
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-col gap-8 -mt-6 z-10">
          <div className="flex gap-4 justify-center">
            {partners.map((partner) => (
              <div
                className={`w-24 h-24 p-3 rounded-lg flex items-center justify-center cursor-pointer hover:bg-primary transition-colors duration-500 ${
                  partner.name === activePartnerName ? "bg-primary" : "bg-white"
                }`}
                onClick={() => setActivePartnerName(partner.name)}
                key={partner.name}
              >
                <img src={partner.logo} alt={`${partner.name} logo`} />
              </div>
            ))}
          </div>
          {activePartnerName === "spa" && (
            <BigCard>
              <Text size="big" color="primary">
                Société Protectrice des Animaux (SPA)
              </Text>
              <FullWithSection>
                <img src={SPA1} alt="Bannière SPA" className="w-full my-4" />
              </FullWithSection>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 gap-2 flex flex-col">
                  <Text>
                    Il s’agit de la première association de protection animale
                    créée en 1845 en France : la bien-nommée Société Protectrice
                    des Animaux (SPA, pour les intimes).
                  </Text>
                  <Text>
                    La SPA gère 56 refuges pour recueillir les animaux
                    abandonnés partout en France, mais pas seulement. Cette
                    association, compte également 12 dispensaires pour soigner
                    les animaux des propriétaires démunis, une Cellule
                    Anti-Trafic (CAT) pour démanteler les élevages clandestins
                    et 16 Clubs Jeunes pour sensibiliser les plus jeunes à la
                    protection animale. En plus de cela, la SPA accompagne
                    financièrement d’autres associations de protection animale
                    et des propriétaires dans le besoin.
                  </Text>
                </div>
                <div className="flex-1 gap-2 flex flex-col">
                  <Text color="primary" className="font-bold">
                    Le résultat ?
                  </Text>
                  <Text>
                    Rien qu’en 2013 : 43 000 animaux ont été accueillis dans les
                    refuges de la SPA, 29 500 ont été adoptés, 5 000 animaux
                    perdus ont retrouvé leur propriétaire grâce à la SPA et 560
                    animaux ont été sauvés d’élevages clandestins !
                  </Text>
                </div>
              </div>
              <FullWithSection>
                <img src={SPA2} alt="Bannière SPA" className="w-full my-4" />
              </FullWithSection>
              <Text>
                Si vous choisissez de devenir cat-sitter pour soutenir la SPA, 1
                euro leur sera reversé pour chaque jour de garde effectué. Une
                garde de chat d’une semaine permet à l’association de nourrir un
                chat de refuge pendant un mois !
              </Text>
            </BigCard>
          )}
          {activePartnerName === "article1" && (
            <BigCard>
              <Text size="big" color="primary">
                Article 1
              </Text>
              <Text>
                Lutter en faveur de l'égalité des chances dans l'éducation tient
                énormément à cœur l'équipe de Prête-moi ton chat. C'est pourquoi
                c'est un immense plaisir pour nous de compter Article 1 parmi
                nos associations partenaires.
              </Text>
              <FullWithSection>
                <img
                  src={Article1Banner}
                  alt="Bannière Article1"
                  className="w-full my-4"
                />
              </FullWithSection>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 gap-2 flex flex-col">
                  <Text color="primary" className="font-bold">
                    Lutter contre l'inégalité des chances.
                  </Text>
                  <Text>
                    Née de la fusion de deux associations majeures de lutte
                    contre l'inégalité des chances, la nouvelle structure œuvre
                    pour une société où l'orientation, la réussite dans les
                    études et l'insertion professionnelle ne dépendent pas des
                    origines sociales, économiques et culturelles ; pour une
                    société où la réussite passe par le lien social et
                    l'engagement citoyen.
                  </Text>
                  <Text>
                    Fondées respectivement en 2004 et 2005, Frateli et Passeport
                    Avenir se sont créées à partir du même constat: Les
                    inégalités sociales et les discriminations pèsent de tout
                    leur poids sur les jeunes issus de milieux populaires. De
                    nombreux talents sont freinés (ou se freinent par
                    autocensure) dans leurs choix d'orientation, leurs parcours
                    d'études, puis leur accès à l'emploi.
                  </Text>
                  <Text>
                    Guidées par des valeurs communes de justice, d'égalité, de
                    fraternité et de liberté, les deux associations ont
                    développé au fil du temps des programmes qui font appel aux
                    mêmes leviers : tisser des liens et organiser des rencontres
                    entre ces jeunes et des volontaires issus du monde
                    professionnel, désireux de partager leurs savoirs et leur
                    engagement.
                  </Text>
                  <Text>
                    Grâce aux programmes de mentorat et grâce aux ateliers
                    organisés partout sur le territoire, ce sont près de 12 000
                    élèves et étudiants issus de milieux modestes déjà
                    accompagnés par les deux associations.
                  </Text>
                </div>
                <div className="flex-1 gap-2 flex flex-col">
                  <Text color="primary" className="font-bold">
                    Une mission. Permettre à chaque talent de choisir et de
                    construire son avenir avec confiance et ambition.
                  </Text>
                  <Text>
                    Chaque année, nous allons chercher ces talents, en liaison
                    avec les enseignants, pour leur fournir l'aide à
                    l'orientation, le soutien méthodologique, la connaissance
                    des filières et des métiers, les réseaux, les opportunités à
                    l'international ; ingrédients indispensables (que l'Ecole
                    seule peine à apporter équitablement à tous) pour leur
                    permettre définir leur projet professionnel, et plus
                    largement, leur projet de vie avec confiance et ambition.
                  </Text>
                  <Text color="primary" className="font-bold">
                    Pour en savoir plus...
                  </Text>
                  <Text>
                    Vous l'aurez compris, il est difficile de présenter la
                    richesse et la diversité des actions mises en place par
                    l'association en quelques lignes et nous vous invitons à
                    consulter le site{" "}
                    <a
                      href="https://article-1.eu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://article-1.eu
                    </a>{" "}
                    pour en savoir plus. Nous espérons que ce partenariat
                    permettra d'aider Article 1 dans toutes ces missions
                  </Text>
                </div>
              </div>
            </BigCard>
          )}
          {activePartnerName === "the-shift-project" && (
            <BigCard>
              <Text size="big" color="primary">
                The Shift Project
              </Text>
              <Text>
                L’équipe Prête-moi ton chat est très sensible aux enjeux
                environnementaux et nous voulions vous donner la possibilité de
                soutenir une organisation qui se bat au quotidien pour protéger
                notre nature.
              </Text>
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                <div className="flex-1 gap-2 flex flex-col">
                  <Text color="primary" className="font-bold">
                    The Shift Project
                  </Text>
                  <Text>
                    La transition énergétique et la lutte contre le changement
                    climatique sont au cœur des préoccupations de l'association
                    française à but non lucratif, Le Shift Project. Elle vise à
                    promouvoir une économie sobre en carbone, c'est-à-dire une
                    économie qui émet peu de gaz à effet de serre et qui réduit
                    notre dépendance aux énergies fossiles.
                  </Text>
                  <Text>
                    Créée en 2010, l'association mène des recherches, élabore
                    des propositions politiques et sensibilise le public aux
                    enjeux liés à la transition énergétique. Elle travaille
                    également en partenariat avec des entreprises et des
                    institutions pour proposer des solutions concrètes et
                    innovantes qui répondent aux défis de la transition
                    énergétique.
                  </Text>
                  <Text>
                    Le Shift Project a pour ambition de réduire l'empreinte
                    carbone de la France, en proposant des alternatives aux
                    énergies fossiles, en développant des sources d'énergie
                    renouvelable et en optimisant l'efficacité énergétique. Elle
                    œuvre également pour l'éducation et la sensibilisation du
                    grand public sur les enjeux énergétiques et
                    environnementaux.
                  </Text>
                </div>
                <div className="flex-1 gap-2 flex flex-col">
                  <img
                    src={TheShiftProject}
                    alt="Bannière Article1"
                    className="w-full my-4"
                  />
                  <Text>
                    Grâce à son expertise et à son engagement, Le Shift Project
                    est devenue une référence dans le domaine de la transition
                    énergétique en France et en Europe. Elle continue de
                    travailler pour construire une économie plus sobre en
                    carbone, pour réduire notre impact sur l'environnement et
                    pour garantir un avenir plus durable pour les générations
                    futures. Pour en savoir plus, vous pouvez consulter leur
                    site web{" "}
                    <a
                      href="https://theshiftproject.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://theshiftproject.org/
                    </a>
                    .
                  </Text>
                </div>
              </div>
            </BigCard>
          )}
        </div>
      </Container>
    </>
  );
}
