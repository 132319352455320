import { useContext, useEffect, useRef, useState } from "react";
import { MdMenu, MdOutlineClose } from "react-icons/md";

import logo from "../images/logo.png";
import { ModeContext, UserContext } from "../routes/root";
import LinkWithMode from "./link-with-mode";
import Text from "./text";
import ProfilePicture from "./profile-picture";
import Icon, { IconNames } from "./icon";
import { Link, useLocation } from "react-router-dom";

function DrawerItem({
  icon,
  withMode = true,
  children,
  to,
  closeFooter,
}: {
  icon: IconNames;
  withMode?: boolean;
  children: string;
  to: string;
  closeFooter: () => void;
}) {
  const LinkComponent = withMode ? LinkWithMode : Link;
  return (
    <LinkComponent
      to={to}
      className="flex items-center justify-between"
      onClick={closeFooter}
    >
      <Text className="font-medium">{children}</Text>
      <Icon icon={icon} color="black"></Icon>
    </LinkComponent>
  );
}

function MobileDrawer({
  opened,
  closeFooter,
}: {
  opened: boolean;
  closeFooter: () => void;
}) {
  const { isLoggedIn } = useContext(UserContext);
  const mode = useContext(ModeContext);
  return (
    <>
      <div
        className={`md:hidden ${
          opened ? "block" : "hidden"
        } absolute h-full w-full top-0 left-0 bg-black transition-all ease-linear opacity-70 z-20`}
        onClick={closeFooter}
      ></div>
      <footer
        className={`md:hidden ${
          opened ? "right-0" : "-right-[90vw]"
        } fixed h-full top-0 bg-white p-8 w-[90vw] transition-all ease-linear z-30 rounded-l-lg flex flex-col`}
      >
        <div className="flex items-center mb-10">
          <div className="flex-1">
            <LinkWithMode to="/" className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="object-contain"
                width="160"
              />
            </LinkWithMode>
          </div>
          <MdOutlineClose
            size="20"
            className="cursor-pointer"
            onClick={closeFooter}
          />
        </div>
        <div className="gap-6 flex flex-col">
          <DrawerItem
            closeFooter={closeFooter}
            withMode={false}
            to={mode === "cat-sitter" ? "/owner" : "/cat-sitter"}
            icon="switch"
          >{`Passer en mode ${
            mode === "cat-sitter" ? "Propriétaire" : "Cat-Sitter"
          }`}</DrawerItem>
          <DrawerItem closeFooter={closeFooter} to="/help" icon="help">
            Aide et Contact
          </DrawerItem>
        </div>
        <div className="h-px bg-lightText opacity-10 my-8 -ml-8 -mr-8"></div>
        <div className="gap-6 flex flex-col">
          <DrawerItem closeFooter={closeFooter} to="/team" icon="corporate">
            Qui sommes nous ?
          </DrawerItem>
          <DrawerItem closeFooter={closeFooter} to="/partners" icon="cases">
            Les associations soutenues
          </DrawerItem>
          <DrawerItem
            closeFooter={closeFooter}
            to="/how-does-it-work"
            icon="businessCenter"
          >
            Fonctionnement du service de garde
          </DrawerItem>
          <DrawerItem closeFooter={closeFooter} to="/cgu" icon="help">
            CGU
          </DrawerItem>
          <DrawerItem closeFooter={closeFooter} to="/legal" icon="law">
            Mentions légales
          </DrawerItem>
        </div>
        <div className="grow"></div>
        {isLoggedIn ? (
          <DrawerItem closeFooter={closeFooter} to="/logout" icon="logout">
            Se déconnecter
          </DrawerItem>
        ) : (
          <DrawerItem closeFooter={closeFooter} to="/login" icon="login">
            S'identifier / S'inscrire
          </DrawerItem>
        )}
      </footer>
    </>
  );
}

function MenuItem({
  to,
  icon,
  text,
  background = false,
}: {
  to: string;
  icon: IconNames;
  text: string;
  background?: boolean;
}) {
  const mode = useContext(ModeContext);
  const location = useLocation();
  const checkLocation = mode === "owner" ? "/owner" + to : "/cat-sitter" + to;

  return (
    <LinkWithMode
      to={to}
      className={`flex flex-col items-center ${
        background && (mode === "owner" ? "md:bg-ocean" : "md:bg-lightning")
      } ${background && "md:px-4 md:py-1 md:rounded-full"}`}
    >
      <Icon
        icon={icon}
        className="md:hidden"
        color={checkLocation === location.pathname ? "primary" : "lightText"}
      />
      <Text
        className={`font-semibold ${
          background && mode === "owner" ? "md:text-white" : "md:text-black"
        } ${
          checkLocation === location.pathname
            ? "text-primary"
            : "text-lightText"
        }`}
      >
        {text}
      </Text>
    </LinkWithMode>
  );
}

export default function Nav() {
  const { user, isLoggedIn } = useContext(UserContext);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const mode = useContext(ModeContext);
  const [footerOpened, setFooterOpened] = useState(false);

  // TODO: This could be isolated into a hook
  useEffect(() => {
    const handleClick: EventListener = (event: Event) => {
      if (
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [menuRef, buttonRef]);

  return (
    <>
      <nav className="bg-white flex justify-between py-4 px-6 items-center md:rounded-none rounded-b-lg relative z-20">
        {/* Mobile drawer */}
        <div className="md:hidden"></div>
        <LinkWithMode to="/">
          <img
            src={logo}
            alt="Logo"
            className="object-contain"
            width="160"
          ></img>
        </LinkWithMode>

        <MdMenu
          className="md:hidden cursor-pointer"
          size="20"
          onClick={() => setFooterOpened(true)}
        />

        <div
          className={`fixed bottom-0 left-0 md:static md:bg-none bg-white w-full flex items-center justify-between md:justify-end md:gap-12 px-6 py-4`}
        >
          <MenuItem to="/" icon="outlineHouse" text="Accueil" />
          <MenuItem
            to={
              user?.user.propositions &&
              user?.user.propositions.length > 0 &&
              mode === "owner"
                ? "/searches"
                : "/search"
            }
            icon="search"
            text="Rechercher"
          />
          {isLoggedIn && (
            <>
              <MenuItem to="/watches" icon="cat" text="Gardes" />
              <MenuItem to="/profile" icon="user" text="Profil" />
              <div
                className="hidden md:block cursor-pointer relative"
                ref={buttonRef}
              >
                <ProfilePicture
                  size="xs"
                  onClick={() => setShowMenu(!showMenu)}
                />
                <div
                  className={`absolute bg-white -right-4 top-8 p-4 border border-primary rounded-lg ${
                    showMenu ? "block" : "hidden"
                  }`}
                  ref={menuRef}
                >
                  <LinkWithMode to="/logout" onClick={() => setShowMenu(false)}>
                    <div className="flex items-center gap-1">
                      <Icon icon="logout" color="black" /> Déconnexion
                    </div>
                  </LinkWithMode>
                </div>
              </div>
            </>
          )}
          {isLoggedIn === false && (
            <MenuItem
              to="/login"
              icon="user"
              text="S'identifier"
              background={true}
            />
          )}
        </div>
      </nav>
      <MobileDrawer
        opened={footerOpened}
        closeFooter={() => setFooterOpened(false)}
      />
    </>
  );
}
