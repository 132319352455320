import { BigCard } from "../components/big-card";
import Container from "../components/container";
import Hero from "../components/hero";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";

export default function HowDoesItWork() {
  return (
    <>
      <Hero image={HeroBlanket} overlay="dark">
        <Container>
          <span className="text-white text-4xl text-center font-extralight pt-6 pb-12">
            Fonctionnement
            <br />
            des gardes
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-col gap-8 -mt-6 z-10">
          <BigCard>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/d12FOxqsXnY?si=NmvQ5qk2gKamxECX"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <Text className="mt-2">
              Prête-moi ton chat est un service collaboratif de garde de chat
              mettant en relation des particuliers, sur internet. Les
              propriétaires de chats, qui souhaitent les faire garder pendant
              les vacances peuvent trouver des particuliers (les hôtes) qui
              aiment la compagnie des chats et ont envie d’en garder sur des
              périodes courtes, de manière bénévole.
            </Text>
            <Text className="mt-2">
              Chaque garde de chat coûte 3€ par jour et génère un don pour une
              association caritative, parmi nos trois associations soutenues (1€
              par jour de garde). Le don est effectué par le propriétaire du
              chat, grâce à l’hôte.
            </Text>
            <Text className="mt-2">
              Le propriétaire fournit la nourriture et la litière à l’hôte.
              L’hôte ne paie rien.
            </Text>
          </BigCard>
        </div>
      </Container>
    </>
  );
}
