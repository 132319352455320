import { useForm } from "react-hook-form";
import { BigCard } from "../components/big-card";
import Container from "../components/container";
import Hero from "../components/hero";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";
import { UserContext } from "./root";
import { ReactNode, useContext, useState } from "react";
import Form, { OnSubmit } from "../components/form";
import Input from "../components/input";
import { contact } from "../api/contacts";
import { successToast } from "../lib/toasts";

interface ContactData {
  name: string;
  mail: string;
  subject: string;
  message: string;
}

function Contact() {
  const { user } = useContext(UserContext);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactData>({
    defaultValues: {
      mail: user?.user.email,
    },
  });

  const onSubmit: OnSubmit<ContactData> = async (data) => {
    await contact(data);
    reset();
    successToast("Votre message a bien été envoyé");
  };

  return (
    <BigCard>
      <Text size="big" className="mb-4">
        Envoyez-nous un message et nous vous contacterons
      </Text>
      <Form
        submitText="Envoyer"
        handleSubmit={handleSubmit}
        duringSubmit={onSubmit}
      >
        <Input
          placeholder="Votre nom"
          name="name"
          register={register}
          errors={errors}
          required={true}
        />
        <Input
          placeholder="Votre email"
          name="mail"
          register={register}
          errors={errors}
          required={true}
        />
        <Input
          placeholder="Sujet de votre message"
          name="subject"
          register={register}
          errors={errors}
          required={true}
        />
        <Input
          type="textarea"
          placeholder="Votre message"
          name="message"
          register={register}
          errors={errors}
          required={true}
        />
      </Form>
    </BigCard>
  );
}

function Question({
  question,
  children,
  number,
}: {
  question: string;
  children: ReactNode;
  number: number;
}) {
  const [open, setOpen] = useState(false);

  return (
    <div
      onClick={() => setOpen(!open)}
      className={`cursor-pointer rounded-xl py-3 px-3 -mx-3 ${
        open && "bg-gray"
      }`}
    >
      <Text className="font-medium">
        #{number} {question}
      </Text>
      <Text
        className={`transition-all overflow-hidden ${
          !open ? "h-0" : "h-auto mt-2"
        }`}
      >
        {children}
      </Text>
    </div>
  );
}

function Faq() {
  return (
    <BigCard>
      <Text size="big">Questions fréquemment posées</Text>
      <div className="flex flex-col gap-5 mt-3">
        <Question
          question="Puis-je être à la fois propriétaire et hôte ?"
          number={1}
        >
          Bien sûr ! Tout propriétaire de chat peut également être hôte.
        </Question>
        <Question question="Comment m’inscrire ?" number={2}>
          Voici deux tutoriels pour vous aider dans l’inscription :{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blog.pretemoitonchat.com/tutoriel-inscription-hote/"
          >
            s’inscrire en tant qu’hôte
          </a>{" "}
          /{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blog.pretemoitonchat.com/tutoriel-inscription-proprietaire/"
          >
            s’inscrire en tant que propriétaire.
          </a>
        </Question>
      </div>
      <div className="h-[1px] bg-lightText opacity-10 -mx-8 md:-mx-12 mt-14 mb-11" />
      <Text size="big">Hôte</Text>
      <div className="flex flex-col gap-5 mt-3">
        <Question
          question="J’ai accepté une garde, mais le propriétaire ne m’a pas contacté. Que faire ?"
          number={3}
        >
          Normalement, il est demandé au propriétaire de contacter l’hôte dès la
          réception de ses coordonnées. Si le propriétaire du chat ne vous a pas
          contacté dans les 5 jours précédant la garde, vous pouvez envoyer un
          mail à contact@pretemoitonchat.com. Nous ferons notre nécessaire pour
          vous fournir une explication sous 48h.
        </Question>{" "}
        <Question
          question="Je n’ai jamais eu de chat, est-ce que je peux quand même m’inscrire ?"
          number={4}
        >
          Oui, il est possible de s’inscrire si vous n’avez jamais eu de chat,
          l’important est de le préciser au propriétaire dans votre description
          et dans vos échanges. Cependant pensez à vous renseigner un maximum
          sur les contraintes engendrées par la garde d’un animal. Vous pouvez
          commencer par lire nos conseils pour garder un chat !
        </Question>{" "}
        <Question
          question="Puis-je garder un chat si j’ai d’autres animaux à la maison ?"
          number={5}
        >
          Oui, vous pouvez garder un chat si vous avez d’autres animaux. Il vous
          faudra juste préciser le nombre, le type et le caractère des vos
          compagnons dans votre Fiche Hôte.
        </Question>{" "}
        <Question
          question="Suis-je rémunéré pour la garde que j’effectue ?"
          number={6}
        >
          Le service repose sur la solidarité, les hôtes ne sont donc pas
          rémunérés pour les gardes. En revanche, chaque garde génère un don à
          une association caritative de son choix (parmi nos trois associations
          soutenues). Le reste de l’argent récolté sert à la bonne gestion du
          site internet.
        </Question>{" "}
        <Question
          question="Que faire en cas d’urgence pendant la garde ?"
          number={7}
        >
          Le propriétaire doit vous fournir un numéro de contact d’urgence avant
          la garde, vous devrez donc l’utiliser. En cas d’accident, vous devrez
          emmener le chat chez le vétérinaire au plus vite.
        </Question>
        <Question
          question="Je suis obligé d’annuler une garde, que faire ?"
          number={8}
        >
          Si vous êtes dans l’obligation d’annuler une garde, vous devez
          prévenir le propriétaire au plus vite ! Si l’annulation s’effectue
          dans les 72h précédant le début de la garde, contactez directement
          contact@pretemoitonchat.com. Notez que même si la garde n’a pas lieu,
          le propriétaire pourra laisser un avis sur votre Fiche Hôte.
        </Question>
        <Question question="Suis-je couvert par une assurance ?" number={9}>
          En tant que particulier, vous êtes couvert par votre assurance
          Responsabilité Civile Personnelle. Nous vous conseillons de contacter
          votre assureur afin qu’il vous fournisse une attestation d’assurance
          et le détail de la couverture, avant la garde
        </Question>
        <Question
          question="Le chat que je vais garder est-il nécessairement stérilisé et vacciné ?"
          number={10}
        >
          La vaccination n’étant pas obligatoire en France, nous ne l’imposons
          pas aux propriétaires. Il en est de même pour la stérilisation.
          N’hésitez pas à demander des précisions au propriétaire sur ces sujets
          avant d’accepter la garde (surtout si vous avez d’autres animaux).
        </Question>
        <Question
          question="Je travaille pendant la journée. Est-il judicieux que je sois hôte alors que je suis absent 10h par jour ?"
          number={11}
        >
          Nous demandons à nos hôtes d’assurer une présence d’au moins une heure
          par jour avec le chat. Cependant, certains chats ont besoin d’une
          présence beaucoup plus forte : il revient donc aux propriétaires de
          s’assurer que l’emploi du temps de l’hôte correspond aux besoins de
          leur chat. En tant qu’hôte, vous avez la responsabilité d’être
          transparent à cet égard dans votre description.
        </Question>
        <Question
          question="J’ai un accès à l’extérieur (jardin, cour, balcon), faut-il que le chat reste enfermé ou puis-je le laisser sortir ?"
          number={12}
        >
          Cela va dépendre de la personnalité du chat : si le chat est fugueur,
          il est très peu conseillé de le laisser à l’extérieur. C’est au
          propriétaire de donner des directives adaptées à l’hôte. L’hôte doit
          cependant surveiller le chat s’il le laisse aller à l’extérieur.
        </Question>
        <Question question="Comment supprimer mon compte hôte ?" number={13}>
          Vous pouvez conserver votre fiche profil tout en n’apparaissant plus
          dans les résultats de recherche. Pour cela, rendez-vous dans votre
          compte et cliquez sur « Ma fiche hôte ». Vous pourrez ainsi cocher «
          non » à « souhaitez vous héberger des chats ? ». Si vous souhaitez
          supprimer votre compte définitivement, vous pouvez cliquer sur
          l’onglet « Mes informations personnelles » (toujours dans votre
          compte) puis sur « supprimer mon compte » en bas du volet.
        </Question>
      </div>
      <div className="h-[1px] bg-lightText opacity-10 -mx-8 md:-mx-12 mt-14 mb-11" />
      <Text size="big">Propriétaire</Text>
      <div className="flex flex-col gap-5 mt-3">
        <Question
          question="Est-il possible de contacter l’hôte avant la garde ?"
          number={14}
        >
          Les coordonnées de l’hôte ne sont transmises qu’une fois la garde
          acceptée et payée. Avant cette étape, il est possible de communiquer
          par commentaire sur le mur de l’hôte. Une fois la garde payée, il est
          possible d’annuler la garde en cas de problème et de demander le
          remboursement des frais engagés.
        </Question>

        <Question
          question="Comment m’assurer que l’hôte est de confiance ?"
          number={15}
        >
          Un système d’avis vous permet de savoir si les précédentes gardes de
          l’hôte se sont bien passées. Si l’hôte est nouveau sur le site, lisez
          bien sa description, son expérience et ses motivations. Quoiqu’il en
          soit, il vous sera possible de rencontrer l’hôte, avant la garde.
        </Question>

        <Question
          question="Je ne sais pas si mon chat est sociable avec les autres animaux"
          number={16}
        >
          <ul>
            <li>Choisir un hôte qui n’a pas d’autre animal de compagnie</li>
            <li>
              Ou s’assurer qu’en cas de problème, votre chat pourra être gardé
              séparément des autres animaux.
            </li>
          </ul>
        </Question>

        <Question
          question="Puis-je faire garder mon chat à domicile ?"
          number={17}
        >
          Non, malheureusement le service Prête-moi ton chat ne propose pas de
          visites à domicile.
        </Question>

        <Question question="Quels sont les modes de paiement ?" number={18}>
          Le paiement s’effectue par carte bancaire via notre partenaire Stripe.
          Pour le moment, nous acceptons uniquement les paiements en euros.
        </Question>

        <Question question="Quel est le coût du service ?" number={19}>
          Le service coûte 3€ par jour de garde par chat, 1€ est par jour par
          chat est reversé à l'association choisie par l'hôte
        </Question>

        <Question
          question="Combien de temps avant la garde dois-je faire ma demande ?"
          number={20}
        >
          Il n’y a pas de limites, mais il vous est fortement conseillé de faire
          votre demande de garde au minimum 7 jours avant la garde.
        </Question>

        <Question
          question="Puis-je rencontrer l’hôte avant la garde ?"
          number={21}
        >
          Bien sûr, nous le recommandons. Une fois que l’hôte a accepté la
          garde, ses coordonnées vous seront fournies : vous pourrez alors
          organiser une rencontre quand vous le souhaitez.
        </Question>

        <Question
          question="Qu’est-ce que je dois fournir à l’hôte ?"
          number={22}
        >
          Il vous est demandé de fournir à l’hôte les croquettes et la litière
          pour couvrir la totalité de la durée de la garde, ainsi qu’un bac à
          litière adapté.
        </Question>

        <Question
          question="Puis-je obtenir un reçu fiscal pour le don que j’ai effectué ?"
          number={23}
        >
          Non, nous ne pouvons pas vous fournir de reçu fiscal. C’est Prête-moi
          ton chat qui verse l’argent collecté aux associations, le don n’est
          donc pas fait en votre nom.
        </Question>

        <Question question="Comment supprimer mon compte ?" number={24}>
          Si vous souhaitez supprimer votre compte définitivement, rendez-vous
          dans la rubrique « mon compte » et cliquez sur l’onglet « Mes
          informations personnelles », puis sur « supprimer mon compte » en bas
          du volet.
        </Question>
      </div>
    </BigCard>
  );
}

export default function Help() {
  return (
    <>
      <Hero image={HeroBlanket} overlay="dark">
        <Container>
          <span className="text-white text-4xl text-center font-extralight pt-6 pb-12">
            Contacter
            <br />
            Prête-moi ton chat
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-col gap-8 -mt-6 z-10">
          <Contact />
          <Faq />
        </div>
      </Container>
    </>
  );
}
