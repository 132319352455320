import { useContext, useEffect, useState } from "react";
import Button from "../components/button";
import { Card } from "../components/card";
import Container from "../components/container";
import Heading from "../components/heading";
import Hero from "../components/hero";
import Text from "../components/text";
import SearchBar from "../components/searchbar";
import { Review, listReviews } from "../api/reviews";
import { ModeContext } from "./root";
import SmallCard from "../components/small-card";
import Icon, { IconNames } from "../components/icon";
import { Link } from "react-router-dom";
import Feature1 from "../images/feature1.jpg";
import Feature2 from "../images/feature2.jpg";
import Feature3 from "../images/feature3.jpg";
import Feature4 from "../images/feature4.jpg";
import IdeaOwner from "../images/idea-owner.jpg";
import IdeaCaySitter from "../images/idea-cat-sitter.jpg";
import LinkWithMode from "../components/link-with-mode";
import { ReviewComponent } from "../components/review";

function Advantage({ children, icon }: { children: string; icon: IconNames }) {
  return (
    <div className="flex items-center">
      <Icon icon={icon} color="black" />
      <Text className="ml-3 font-medium">{children}</Text>
    </div>
  );
}

function Feature({ children, img }: { children: string; img: string }) {
  return (
    <div>
      <img src={img} alt="cat" className="rounded-lg w-full mb-3" />
      <Text className="text-xs md:text-base font-medium" size="custom">
        {children}
      </Text>
    </div>
  );
}

export default function Home() {
  const mode = useContext(ModeContext);
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    listReviews(mode === "owner" ? "host" : "master").then((res) =>
      setReviews(res?.reviews ?? [])
    );
  }, [mode]);

  return (
    <>
      <Hero>
        <Container>
          {mode === "owner" && (
            <SearchBar placeholder="Recherche De Cat-Sitter" />
          )}
          <div className="max-w-[80%] m-auto">
            <Heading
              className="md:text-4xl md:pt-6 md:pb-12 font-light"
              color={mode === "cat-sitter" ? "black" : "white"}
              main={true}
            >
              {mode === "owner"
                ? "Confiez votre chat à un cat-sitter de confiance près de chez vous"
                : "Trouvez un heureux chat à garder près de chez vous"}
            </Heading>
          </div>
        </Container>
      </Hero>
      <Container>
        <div className="-mt-7 relative z-10">
          {mode === "owner" ? (
            <LinkWithMode to="/search">
              <Button>Faites Garder Votre Chat</Button>
            </LinkWithMode>
          ) : (
            <SearchBar placeholder="Cherchez Des Chats Qui Ont Besoin De Vous" />
          )}
        </div>
        <div className="mt-6 flex gap-4">
          <SmallCard
            text={
              mode === "owner"
                ? "Laissez Votre Chat Chez Un Cat-Sitter"
                : "Gardez Des Chats Chez Vous"
            }
            cta={mode === "owner" ? "Commencer" : "Voir Les Annonces"}
            ctaLink="/search"
            icon="suitcase"
          />
          <SmallCard
            text={
              mode === "owner"
                ? "À la recherche d'un chat à garder ?"
                : "À la recherche d'un cat sitter ?"
            }
            cta={mode === "owner" ? "Espace cat-sitter" : "Espace propriétaire"}
            icon="village"
            ctaLink="/"
            reverse
          />
        </div>

        <Card
          className="mt-6 md:mt-8"
          header={
            mode === "owner"
              ? "Des Cat-Sitters Près De Chez Vous À Partir De 3 € Par Jour"
              : "Soutenez Des Associations En Gardant Des Chats"
          }
        >
          <div className="flex flex-col gap-4">
            <Advantage icon="shield">
              {mode === "owner"
                ? "Des avis vérifiés sur les cat-sitters, pour laisser votre chat en toute tranquillité."
                : "Pour une garde solidaire, nous reversons 1 € par jour et par chat à nos associations soutenues."}
            </Advantage>
            <Advantage icon="peopleSearch">
              {mode === "owner"
                ? "Des cat sitters bienveillants pour des gardes partout en France."
                : "Tenez compagnie à des chats partout en France et toute l’année."}
            </Advantage>
            <Advantage icon="heart">
              {mode === "owner"
                ? "Pour une garde solidaire, nous nous engageons à reverser 1€ par jour et par chat à nos associations soutenues."
                : "Trouvez le mode de garde qui vous convient, que ce soit en hébergeant ou en visitant un chat près de chez vous."}
            </Advantage>
          </div>
        </Card>
        <div className="mt-6 md:mt-8">
          <Heading>Des Gardes Pour Tous Les Chats</Heading>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-4">
            <Feature img={Feature1}>
              {mode === "owner"
                ? "Déjà 10 ans d'expérience et plus de 8000 gardes réalisées"
                : "Collectez De L’argent Pour Des Associations"}
            </Feature>
            <Feature img={Feature2}>
              {mode === "owner"
                ? "Hébergement Chez Le Cat-Sitter"
                : "Hébergement Des Chats Chez Vous"}
            </Feature>
            <Feature img={Feature3}>
              {mode === "owner"
                ? "Des Hôtes Avec Jardin"
                : "Des Gardes Toute L’année"}
            </Feature>
            <Feature img={Feature4}>
              {mode === "owner"
                ? "Des Hôtes Attentionnés Bénévoles"
                : "Un Chat À La Maison"}
            </Feature>
          </div>
        </div>
        <div className="mt-5 md:mt-8">
          <Heading>Les derniers avis de nos membres</Heading>
          <div className="bg-white rounded-lg w-full">
            {reviews.map((review) => (
              <ReviewComponent key={review.id} review={review} />
            ))}
          </div>
        </div>
        <div className="mt-9">
          <img
            className="rounded-t-lg w-full h-[173px] object-cover"
            src={mode === "owner" ? IdeaOwner : IdeaCaySitter}
            alt="chat"
          ></img>
          <div className="bg-reversePrimary text-reverseSecondary rounded-b-lg px-7 py-3">
            <h3 className="text-xl font-medium">
              {mode === "owner"
                ? "Et si vous gardiez un chat ?"
                : "Vous cherchez un cat-sitter ?"}
            </h3>

            <Text className={`${mode === "owner" && "opacity-50"} mt-2.5`}>
              Si vous souhaitez faire garder votre chat, rendez-vous sur la
              section propriétaire. Trouvez des cat-sitters bienveillants, qui
              sauront cajoler votre chat.
            </Text>
            <Link
              className={`bg-white font-medium rounded-2xl mt-2.5 inline-block px-4 py-1 text-reversePrimary`}
              to={mode === "cat-sitter" ? "/owner" : "/cat-sitter"}
            >
              {mode === "owner" ? "Devenez Cat-Sitter" : "Espace propriétaire"}
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
}
